import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService, NbTokenStorage } from '@nebular/auth';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(private authService: NbAuthService,private router: Router,
              private nbtokenStorage: NbTokenStorage,) {
  }
  canActivate() {
    return this.authService.isAuthenticated()
    .pipe(
      tap(authenticated=>{
        if(!authenticated){
          this.authService.logout('email')
          this.nbtokenStorage.clear()
          this.router.navigate(['auth/login']);
        }
      })
    )
  }
  
}
