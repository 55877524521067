import { Observable } from 'rxjs';
import { Permit } from './permits';

export interface User {
  id:string
  active:boolean
  name: string;
  last_name:string
  email:string;
  password:string;
  celphone:string
  permits:Permit[]
  picture: string;
  permitList?:string[]
  estado:string
}

export interface Contacts {
  user: User;
  type: string;
}

export interface RecentUsers extends Contacts {
  time: number;
}

export abstract class UserData {
  // abstract getUsers(): Observable<User[]>;
  // abstract getContacts(): Observable<Contacts[]>;
  // abstract getRecentUsers(): Observable<RecentUsers[]>;
}
