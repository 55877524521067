import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Contacts, RecentUsers, User, UserData } from '../data/users';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService extends UserData {
  private time: Date = new Date;
  empty= { name: '', picture: '' ,email:'',last_name:'',password:'',celphone:'',id:'',active:true,permits:[]} as User
  model="/user/"
  constructor(private http: HttpClient) {super()}
  private users = {
    nick: { name: 'Nick Jones', picture: 'assets/images/nick.png' ,email:'',last_name:'',password:'',celphone:'',id:'',active:false,permits:[]},
    eva: { name: 'Eva Moor', picture: 'assets/images/eva.png' ,email:'',last_name:'',password:'',celphone:'',id:'',active:false,permits:[]},
    jack: { name: 'Jack Williams', picture: 'assets/images/jack.png' ,email:'',last_name:'',password:'',celphone:'',id:'',active:false,permits:[]},
    lee: { name: 'Lee Wong', picture: 'assets/images/lee.png' ,email:'',last_name:'',password:'',celphone:'',id:'',active:false,permits:[]},
    alan: { name: 'Alan Thompson', picture: 'assets/images/alan.png' ,email:'',last_name:'',password:'',celphone:'',id:'',active:false,permits:[]},
    kate: { name: 'Kate Martinez', picture: 'assets/images/kate.png' ,email:'',last_name:'',password:'',celphone:'',id:'',active:false,permits:[]},
  };
  private types = {
    mobile: 'mobile',
    home: 'home',
    work: 'work',
  };
  // private contacts: Contacts[] = [
  //   { user: this.users.nick, type: this.types.mobile },
  //   { user: this.users.eva, type: this.types.home },
  //   { user: this.users.jack, type: this.types.mobile },
  //   { user: this.users.lee, type: this.types.mobile },
  //   { user: this.users.alan, type: this.types.home },
  //   { user: this.users.kate, type: this.types.work },
  // ];
  // private recentUsers: RecentUsers[]  = [
  //   { user: this.users.alan, type: this.types.home, time: this.time.setHours(21, 12)},
  //   { user: this.users.eva, type: this.types.home, time: this.time.setHours(17, 45)},
  //   { user: this.users.nick, type: this.types.mobile, time: this.time.setHours(5, 29)},
  //   { user: this.users.lee, type: this.types.mobile, time: this.time.setHours(11, 24)},
  //   { user: this.users.jack, type: this.types.mobile, time: this.time.setHours(10, 45)},
  //   { user: this.users.kate, type: this.types.work, time: this.time.setHours(9, 42)},
  //   { user: this.users.kate, type: this.types.work, time: this.time.setHours(9, 31)},
  //   { user: this.users.jack, type: this.types.mobile, time: this.time.setHours(8, 0)},
  // ];

  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

  // getContacts(): Observable<Contacts[]> {
  //   return observableOf(this.contacts);
  // }

  // getRecentUsers(): Observable<RecentUsers[]> {
  //   return observableOf(this.recentUsers);
  // }
  async getAllUsers(){
    return this.http.get(environment.apiUrl + this.model + "all").toPromise()
  }
  //UPDATE ITEM
  async update(item: User){
    return this.http.patch(environment.apiUrl + this.model + "update",item).toPromise()
  }
  //UPDATE ITEM
  async replaceCollection(item: User){
    return this.http.patch(environment.apiUrl + this.model + "replaceCollection",item).toPromise()
  }
  //CREATE ITEM
  async create(item: User){
    return this.http.post(environment.apiUrl + this.model + "create", item).toPromise()
  }
}
